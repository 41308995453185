<script setup lang="ts">
import { useHead } from "@unhead/vue";
import { useAuthStore } from "~/store/useAuthStore";
import DebugBar from "~/components/DebugBar.vue";
import TheToaster from "~/components/ui/toast/TheToaster.vue";

useHead({
  title: "CADRO",
  meta: [{ name: "description", content: "Your app description" }],
  link: [
    {
      rel: "manifest",
      href: "/site.webmanifest",
    },
    {
      rel: "apple-touch-icon",
      type: "image/png",
      href: "/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      href: "/favicon-32x32.png",
    },
    {
      rel: "icon",
      href: "/favicon.ico",
      sizes: "any",
    },
  ],
});
const authStore = useAuthStore();
const user = computed(() => authStore.user);
</script>

<template>
  <div
    v-if="!user && !$route.path.includes('/auth') && !$route.meta.publicPage"
    class="mt-20 text-center"
  >
    Signing in ...
  </div>
  <div v-else class="h-full">
    <RouterView />
    <TheToaster />
    <CookieConsentModal />
    <MultimediaFilterModal v-if="user" />
    <AllowNotificationsModal v-if="user" />
    <DebugBar v-if="$route.query.debug" />
  </div>
</template>
