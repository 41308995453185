<script setup lang="ts">
interface Props {
  itemCount?: number;
  visibleItems?: number[];
}
const props = withDefaults(defineProps<Props>(), {
  itemCount: 1,
  visibleItems: () => [],
});

let currentItem = ref(1);

const dotsToShow = computed(() => {
  if (currentItem.value + props.visibleItems.length > props.itemCount) {
    return props.itemCount - (Math.max(props.visibleItems.length, 1) - 1);
  }
  return props.itemCount;
});

watch(
  () => props.visibleItems,
  async (newVisibleItems: number[]) => {
    if (newVisibleItems.length) {
      currentItem.value = Math.min(...props.visibleItems);
    }
  },
  {
    deep: true,
  },
);
</script>

<template>
  <div class="carousel-scrollbar-container flex gap-2">
    <div
      v-for="itemNumber in dotsToShow"
      :key="itemNumber"
      class="carousel-scrollbar-dot bg-dark-gray h-1 w-1"
      :class="{
        'bg-almost-black! w-9!':
          itemNumber === currentItem ||
          (dotsToShow < currentItem && itemNumber === dotsToShow),
      }"
    ></div>
  </div>
</template>
