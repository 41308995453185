import { defineStore } from "pinia";
import { Performance } from "~/types/account/Performance";
import { Modal } from "~/types/Modal";
import { useLocalStorage } from "@vueuse/core";
import { useHttpClient } from "~/composables/http-client";

export const useGeneralStore = defineStore("general", {
  state: () => ({
    blurCurrency: useLocalStorage("blurCurrency", true),
    showPerformance: useLocalStorage("performanceMode", false),
    performancePeriod: "previousMonth" as keyof Performance,
    modals: {} as Record<string, Modal>,
    systemMessage: undefined as number | undefined,
    iosAppAvailableModalFinished: false,
  }),
  actions: {
    openModal(name: string, data?: any) {
      if (!this.modals[name]) {
        this.modals[name] = {} as Modal;
      }
      this.modals[name].shown = true;
      this.modals[name].data = data;
      document.body.classList.add("overflow-hidden!");
    },
    closeModal(name: string) {
      if (!this.modals[name]) {
        this.modals[name] = {} as Modal;
      }
      this.modals[name].shown = false;
      this.modals[name].data = undefined;
      document.body.classList.remove("overflow-hidden!");
    },
    async fetchInformativeMessage() {
      let message = await useHttpClient().users.fetchInformativeMessage();
      if (message.content && !this.systemMessage && this.systemMessage !== 0) {
        this.systemMessage = useToast().permanent(message.content);
      }
      if (
        !message?.content &&
        (this.systemMessage || this.systemMessage === 0)
      ) {
        useToast().dismiss(this.systemMessage);
        this.systemMessage = undefined;
      }
    },
  },
});
