<script setup lang="ts">
import CurrencySpan from "~/components/CurrencySpan.vue";
import CaTag from "~/components/ui/CaTag.vue";
import { Account } from "~/types/account/Account";
import PortfolioAssetClasses from "~/components/portfolio/PortfolioAssetClasses.vue";

interface Props {
  account?: Account;
  block?: boolean;
}
const props = defineProps<Props>();

const background =
  useAccountsStore().accountBackgrounds[props.account?.id || "0"];
</script>

<template>
  <div
    v-if="account"
    class="bg-cover bg-center p-6"
    :class="[
      background,
      {
        'card xs:min-w-[19rem] xs:max-w-[19rem] h-[370px] max-w-[17rem] min-w-[17rem] cursor-pointer rounded-md':
          !block,
        'sm:rounded-md': block,
      },
    ]"
    data-testclass="portfolioCard"
  >
    <div class="flex h-full flex-col justify-between">
      <div>
        <CaTag
          v-if="!block && account.role !== 'OWNER'"
          :text="$t('accounts_role_viewer').toString()"
          data-testclass="portfolioCard-viewer"
        ></CaTag>
        <div
          v-if="block"
          class="text-lg font-light"
          data-testclass="portfolioCard-accountType"
        >
          {{ account?.accountType }}
        </div>
        <div class="text-lg font-normal" data-testclass="portfolioCard-name">
          {{ account?.name }}
        </div>
      </div>
      <div class="flex flex-col gap-6">
        <PortfolioAssetClasses
          class="hidden md:flex"
          :investments="account.investments"
          :max-count="block ? -1 : 6"
        />
        <PortfolioAssetClasses
          class="xs:flex hidden md:hidden"
          :investments="account.investments"
          :max-count="block ? 8 : 6"
        />
        <PortfolioAssetClasses
          class="xs:hidden flex"
          :investments="account.investments"
          :max-count="block ? 6 : 6"
        />
        <div class="bg-almost-black h-[1px] w-4/5" />
        <CurrencySpan
          :value="account.currentValue"
          :currency="account.currency"
          :performance="account.performance"
          class="text-xl font-medium"
          data-testclass="portfolioCard-value"
        ></CurrencySpan>
      </div>
    </div>
  </div>
</template>
