<script setup lang="ts">
import { ToastOptions, ToastType } from "~/composables/useToast";

interface Props {
  id: number;
  title: string;
  text: string;
  type: ToastType;
  options: ToastOptions;
}
const props = defineProps<Props>();

function onClick() {
  if (props.options.onClick) {
    props.options.onClick();
  }
  useToast().dismiss(props.id);
}

const icon = computed(() => {
  switch (props.type) {
    case "SUCCESS":
      return "Check green";
    case "ERROR":
      return "Alert circle red";
    case "INFO":
      return "Info";
    case "PERMANENT":
      return "Alert circle red";
    default:
      return "";
  }
});
</script>

<template>
  <div
    class="text-almost-black pointer-events-auto flex w-full cursor-pointer justify-between gap-4 rounded-md bg-white px-4 py-3 shadow-lg sm:px-6 sm:py-5"
    data-testclass="toast"
    @click="onClick"
    @mouseenter="useToast().pauseToast(props.id)"
    @mouseleave="useToast().resumeToast(props.id)"
  >
    <div class="shrink-0">
      <CaIcon :name="icon" class="" size="24" />
    </div>
    <div class="grow">
      <div v-if="title || text">
        <div
          v-if="title"
          class="mb-1"
          :class="{
            'text-accent-red': type === 'ERROR',
            'text-accent-green': type === 'SUCCESS',
          }"
          data-testclass="toast-title"
        >
          {{ title }}
        </div>
        <div v-if="text" class="toast-text" data-testclass="toast-text">
          {{ text }}
        </div>
      </div>
      <slot v-else></slot>
    </div>
    <div class="shrink-0">
      <CaIcon class="h-3 w-3 cursor-pointer" name="Close" />
    </div>
  </div>
</template>
