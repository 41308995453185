import { defineStore } from "pinia";
import { TokenResponse } from "~/types/auth";
import { useHttpClient } from "~/composables/http-client";
import { useTokenStore } from "~/store/useTokensStore";
import { StorageSerializers, useStorage } from "@vueuse/core";
import { User } from "~/types/user/User";
import { LoginForm } from "~/types/forms/LoginForm";
import { PinForm } from "~/types/forms/PinForm";
import { HTTPError } from "ky";
import { useNotificationsStore } from "~/store/useNotificationsStore";
import { useToast } from "~/composables/useToast";

const posthogEvent = usePosthogEvent();
export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: useStorage<User | null>("user", null, undefined, {
      serializer: StorageSerializers.object,
    }),
    loginForm: new LoginForm(),
    pinForm: new PinForm(),

    fetching: false,
    pushing: {
      login: false,
      devices: false,
    },
  }),

  actions: {
    async sendSms() {
      this.pinForm.clearErrors();
      this.loginForm.clearErrors();
      this.pushing.login = true;
      try {
        await useHttpClient().auth.sendSms(this.loginForm.data.email);
      } catch (e) {
        if (e instanceof HTTPError) {
          const body = await e.response.json();
          if (body.message === "TOO_MANY_REQUESTS") {
            useToast().error(
              "Too many codes sent",
              "Try resending again in 10 minutes.",
            );
          } else {
            useToast().error(
              "Something went wrong",
              "Try again later. If the error persists, contact us.",
            );
          }
        } else {
          this.loginForm.errors = e as Record<string, string>;
        }
        this.pushing.login = false;
        return false;
      }
      this.pushing.login = false;
      return true;
    },

    async authWithSmsPin() {
      this.pinForm.clearErrors();
      this.pushing.login = true;
      try {
        const response: TokenResponse = await useHttpClient().auth.fetchToken(
          this.loginForm.data.email,
          this.pinForm.data.pin,
        );
        useTokenStore().setTokens(
          response.token,
          response.refreshToken,
          this.loginForm.data.email,
        );
        this.user = await useHttpClient().users.getUserData();
        posthogEvent.identify(this.user);
        posthogEvent.userAuthenticated("sms");
        this.pushing.login = false;
        return response;
      } catch (e) {
        if (e instanceof HTTPError) {
          const body = await e.response.json();
          if (body.message === "INVALID_PIN_OR_VERIFICATION_NOT_EXISTS") {
            this.pinForm.errors.pin =
              "Invalid code or email. Please check both and try again.";
          } else if (body.message === "EXPIRED_PIN") {
            this.pinForm.errors.pin =
              "Your code has expired. Resend code and try again.";
          } else if (body.message === "TOO_MANY_REQUESTS") {
            useToast().error(
              "Too many incorrect attempts",
              "Try resending again in 10 minutes",
            );
          } else {
            useToast().error(
              "Something went wrong",
              "Resend code and try again.",
            );
          }
        } else {
          this.pinForm.errors = e as Record<string, string>;
        }
        this.pushing.login = false;
      }
    },

    async authWithDevice() {
      this.pushing.login = true;
      let returnStatus = "";
      try {
        const response = await useHttpClient().webauthn.authenticate(
          this.loginForm.data.email,
        );
        useTokenStore().setTokens(
          response.token,
          response.refreshToken,
          this.loginForm.data.email,
        );
        this.user = await useHttpClient().users.getUserData();
        posthogEvent.identify(this.user);
        posthogEvent.userAuthenticated("passkey");
        returnStatus = "OK";
      } catch (e: any) {
        if (e.message === "NO_DEVICE") {
          returnStatus = e.message;
        } else {
          returnStatus = "ERROR";
        }
      }
      this.pushing.login = false;
      return returnStatus;
    },

    async refreshTokens() {
      if (
        !useTokenStore().getTokens.email ||
        !useTokenStore().getTokens.refreshToken
      ) {
        throw "User not logged in";
      }
      let response: TokenResponse | null = null;
      try {
        await this.checkTR(useTokenStore().getTokens.email);
        response = await useHttpClient().auth.refreshToken(
          useTokenStore().getTokens.email,
          useTokenStore().getTokens.refreshToken,
        );
      } catch (e) {
        if (e instanceof HTTPError) {
          const body = await e.response.json();
          if (body.message === "INVALID_REFRESH_TOKEN") {
            console.log("The refresh token expired");
          }
          return response;
        } else {
          throw e;
        }
      }
      if (response) {
        useTokenStore().setTokens(response.token, response.refreshToken);
        this.user = await useHttpClient().users.getUserData();
      }
      return response;
    },

    async logout() {
      if ("Notification" in window && Notification.permission) {
        await useNotificationsStore().logout();
      }
      try {
        await useHttpClient().auth.revokeToken(
          useTokenStore().getTokens.refreshToken,
        );
      } catch (e) {}
      this.clearLocalStorage();
      posthogEvent.loggedOut();
      posthogEvent.reset();
      location.reload();
    },

    async registerDevice() {
      this.pushing.devices = true;
      try {
        const result = await useHttpClient().webauthn.register();
        if (result) {
          useToast().success(
            "Registration successful",
            "Manage your authentication devices in My profile",
          );
          this.pushing.devices = false;
          posthogEvent.authenticatorRegistered();
          return 0;
        }
      } catch (e) {
        useToast().error(
          "Registration not successful",
          "Manage your authentication devices in My profile",
        );
      }
      this.pushing.devices = false;
      return 1;
    },

    clearLocalStorage() {
      useTokenStore().clearTokens();
      this.user = null;
      this.loginForm.clear();
      this.pinForm.clear();
    },

    async checkTR(email: string) {
      this.fetching = true;
      const response = await useHttpClient().auth.fetchTR(email);
      useTokenStore().setTR(response.tr);
      useHttpClient().switchApi();

      this.fetching = false;
    },
  },
});
