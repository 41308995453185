<script setup lang="ts">
function getApp() {
  useGeneralStore().closeModal("AppAvailableModal");
  window
    .open("https://apps.apple.com/app/cadro/id1659426835", "_blank")
    ?.focus();
}
</script>

<template>
  <CaModalWindow name="AppAvailableModal" modal-class="bottom-0">
    <div class="mb-4 text-lg">{{ $t("misc_app_available_title") }}</div>
    <p class="mb-8 font-light">
      {{ $t("misc_app_available_text") }}
    </p>
    <CaButton
      block
      color="primary"
      data-testid="appAvailableModal-button-accept"
      @click="getApp()"
      >{{ $t("misc_app_available_button") }}</CaButton
    >
    <CaButton
      block
      color="secondary"
      class="mt-2"
      data-testid="appAvailableModal-button-decline"
      @click="useGeneralStore().closeModal('AppAvailableModal')"
      >{{ $t("generic_button_close") }}</CaButton
    >
  </CaModalWindow>
</template>
