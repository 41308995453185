<script setup lang="ts">
const tokenStore = useTokenStore();
const toast = useToast();

const triggerError = () => {
  throw new Error("User-triggered Sentry test error");
};
</script>

<template>
  <div class="fixed top-20 left-1 z-20 rounded-sm bg-white py-1">
    <ul>
      <li
        class="text-darker-gray hover:bg-light-gray cursor-pointer px-2 py-0.5 text-center font-medium"
        title="Sentry Error"
        @click="triggerError"
      >
        Er
      </li>
      <hr />
      <li
        class="text-darker-gray hover:bg-light-gray cursor-pointer px-2 py-0.5 text-center font-medium"
        title="Break Access Token"
        @click="tokenStore.breakAccessToken"
      >
        AT
      </li>
      <li
        class="text-darker-gray hover:bg-light-gray cursor-pointer px-2 py-0.5 text-center font-medium"
        title="Break Refresh Token"
        @click="tokenStore.breakRefreshToken"
      >
        RT
      </li>
      <hr />
      <li
        class="text-darker-gray hover:bg-light-gray cursor-pointer px-2 py-0.5 text-center font-medium"
        title="Show a toast"
        @click="toast.success('Success', 'This is a test success toast')"
      >
        <CaIcon name="Toast" class="fill-green-700"></CaIcon>
      </li>
      <li
        class="hover:bg-light-gray cursor-pointer px-2 py-0.5 text-center font-medium"
        title="Show a toast"
        @click="toast.error('Error', 'This is a test error toast')"
      >
        <CaIcon name="Toast" class="fill-red-700"></CaIcon>
      </li>
      <li
        class="text-darker-gray hover:bg-light-gray cursor-pointer px-2 py-0.5 text-center font-medium"
        title="Show a toast"
        @click="
          toast.notification('Info', 'This is a test info toast', () =>
            console.log('clicked'),
          )
        "
      >
        <CaIcon name="Toast"></CaIcon>
      </li>
    </ul>
  </div>
</template>
