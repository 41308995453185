<script setup lang="ts">
const emit = defineEmits(["triggered"]);

let trigger = ref<HTMLElement | null>(null);

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        emit("triggered");
      }
    },
    { root: null, threshold: 0 },
  );
  if (trigger.value) {
    observer.observe(trigger.value);
  }
});
</script>

<template>
  <div ref="trigger" class="h-10 w-full"></div>
</template>
