<script setup lang="ts">
interface Props {
  value?: number;
  format?: "short" | "long";
  onlyMin?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  value: 0,
  format: "short",
  onlyMin: false,
});

const displayRuntime = computed(() => {
  const minutes = Math.floor(props.value / 60);
  if (props.onlyMin) {
    return `${minutes}min`;
  }
  const seconds = props.value % 60;
  if (props.format === "short") {
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  } else {
    return `${minutes}min ${seconds}s`;
  }
});
</script>

<template>
  <span>{{ displayRuntime }}</span>
</template>
