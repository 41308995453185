<script lang="ts" setup>
interface Props {
  block?: boolean;
  size?: "sm" | "md" | "lg";
  color?: "primary" | "danger" | "secondary";
  invert?: boolean;
  noPadding?: boolean;
  loading?: boolean;
}
withDefaults(defineProps<Props>(), {
  block: false,
  size: "md",
  color: "primary",
  invert: false,
  noPadding: false,
  loading: false,
});

const attrs = useAttrs();
</script>

<template>
  <button
    v-bind="$attrs"
    type="button"
    class="cursor-pointer rounded-md leading-[1.5rem] font-normal"
    :class="{
      'disabled cursor-not-allowed opacity-50': attrs.disabled,
      'block w-full': block,
      'px-3.5 py-1.5 leading-[1rem]': size === 'sm',
      'px-6 py-2': size === 'md',
      'rounded-lg! px-8 py-4 font-medium': size === 'lg',
      'bg-almost-black text-white': color === 'primary' && !invert,
      'text-almost-black bg-transparent': color === 'primary' && invert,
      'bg-accent-red text-white': color === 'danger' && !invert,
      'text-accent-red bg-transparent': color === 'danger' && invert,
      'min-w-0! p-0!': noPadding,
      'border-almost-black border bg-white': color === 'secondary',
      'hover:bg-lighter-gray focus-visible:bg-lighter-gray active:bg-light-gray':
        color === 'secondary' && !attrs.disabled,
    }"
  >
    <CaSpinner v-if="loading" size="xs"></CaSpinner>
    <slot v-else> Button text </slot>
  </button>
</template>

<style scoped>
button {
  min-width: 2.375rem;
}
</style>
