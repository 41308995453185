<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";

const { toasts } = useToast();

function handleVisibilityChange() {
  if (document.hidden) {
    useToast().pauseAllToasts();
  } else {
    useToast().resumeAllToasts();
  }
}

onMounted(() => {
  document.addEventListener("visibilitychange", handleVisibilityChange);
});
onUnmounted(() => {
  document.removeEventListener("visibilitychange", handleVisibilityChange);
});
</script>

<template>
  <div
    class="pointer-events-none fixed top-10 right-2 left-2 z-50 h-full py-8 sm:top-20 sm:right-8 sm:left-auto sm:w-[512px]"
    data-testclass="toaster"
  >
    <TransitionGroup
      name="toast"
      tag="div"
      class="flex flex-col gap-2 sm:gap-5"
      enter-active-class="transition-all duration-300 ease-out"
      enter-from-class="opacity-0 translate-x-full"
      enter-to-class="opacity-100 translate-x-0"
      leave-active-class="transition-all duration-200 ease-in"
      leave-from-class="opacity-100 translate-x-0"
      leave-to-class="opacity-0 translate-x-full"
    >
      <div
        v-for="toast in toasts"
        :key="toast.id"
        data-testclass="toast-wrapper"
      >
        <CaToast v-bind="toast" />
      </div>
    </TransitionGroup>
  </div>
</template>
