import { ref } from "vue";
import { PausableTimer } from "~/utils/PausableTimer";

export type ToastType = "SUCCESS" | "ERROR" | "INFO" | "PERMANENT";

export interface ToastOptions {
  timeout?: number | false;
  onClick?: () => void;
}

export interface Toast {
  id: number;
  title: string;
  text: string;
  type: ToastType;
  options: ToastOptions;
  timer?: PausableTimer;
}

const toasts = ref<Toast[]>([]);
let toastId = 0;

function generateId(): number {
  return ++toastId;
}

function pauseAllToasts() {
  toasts.value.forEach((toast) => {
    toast.timer?.pause();
  });
}

function resumeAllToasts() {
  toasts.value.forEach((toast) => {
    toast.timer?.resume();
  });
}

function addToast(
  title: string,
  text: string,
  type: ToastType,
  options: ToastOptions = {},
): number {
  const existingToast = toasts.value.find(
    (t) => t.title === title && t.text === text && t.type === type,
  );
  if (existingToast) {
    return existingToast.id;
  }
  const id = generateId();

  const toast: Toast = {
    id,
    title,
    text,
    type,
    options,
  };

  if (options.timeout !== false) {
    const timeout = options.timeout ?? 8000;
    toast.timer = new PausableTimer(timeout, () => removeToast(id));
  }
  toasts.value.push(toast);
  return id;
}

function removeToast(id: number) {
  const index = toasts.value.findIndex((toast) => toast.id === id);
  if (index > -1) {
    const toast = toasts.value[index];
    toast.timer?.clear();
    toasts.value.splice(index, 1);
  }
}

function pauseToast(id: number): void {
  const toast = toasts.value.find((t) => t.id === id);
  toast?.timer?.pause();
}

function resumeToast(id: number): void {
  const toast = toasts.value.find((t) => t.id === id);
  toast?.timer?.resume();
}

export function useToast() {
  function dismissAll() {
    toasts.value.forEach((toast) => {
      toast.timer?.clear();
    });
    toasts.value = [];
  }

  function dismiss(id: number) {
    removeToast(id);
  }

  function success(title = "", text = "") {
    addToast(title, text, "SUCCESS");
  }

  function error(title = "", text = "") {
    addToast(title, text, "ERROR");
  }

  function notification(title = "", text = "", onClick = () => {}) {
    addToast(title, text, "INFO", {
      timeout: false,
      onClick,
    });
  }

  function permanent(text = ""): number {
    return addToast("", text, "PERMANENT", {
      timeout: false,
    });
  }

  return {
    dismissAll,
    dismiss,
    success,
    error,
    notification,
    permanent,
    pauseToast,
    resumeToast,
    pauseAllToasts,
    resumeAllToasts,
    toasts,
  };
}
