export class PausableTimer {
  private readonly originalTime: number;
  private timeoutId?: number;
  private remainingTime: number;
  private startTime?: number;
  private readonly callback: () => void;

  constructor(duration: number, callback: () => void) {
    this.originalTime = duration;
    this.remainingTime = duration;
    this.callback = callback;
    this.start();
  }

  private start(): void {
    this.startTime = Date.now();
    this.timeoutId = window.setTimeout(() => {
      this.callback();
    }, this.remainingTime);
  }

  pause(): void {
    if (!this.timeoutId) return;

    window.clearTimeout(this.timeoutId);
    this.remainingTime =
      this.remainingTime - (Date.now() - (this.startTime ?? Date.now()));
    this.startTime = undefined;
    this.timeoutId = undefined;
  }

  resume(): void {
    if (this.timeoutId) return;
    this.start();
  }

  restart(): void {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.remainingTime = this.originalTime;
    this.start();
  }

  clear(): void {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }
}
